import { colors, Box, darken } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FC, ReactNode, memo } from "react";

import { useCalendarContext } from "./CalendarContext";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

type CalendarRowType = FC<{
  label: ReactNode;
  onRenderDay?: (day: Dayjs) => ReactNode;
  onDayClick?: (day: Dayjs) => void;
}>;

export const CalendarRow: CalendarRowType = memo(
  ({ label, rowId, onRenderDay, onDayClick }) => {
    const { calendarRange } = useCalendarContext();
    return (
      <>
        <Box
          sx={({ spacing, palette: { background, getContrastText } }) => ({
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: spacing(2),
            border: "1px solid rgba(224, 224, 224)",
            backgroundColor: background.paper,
            color: getContrastText(background.paper),
          })}
        >
          {label}
        </Box>
        {calendarRange.map((day, index) => {
          const isToday = day.isSame(dayjs(), "day");
          const isWeekendDay = day.day() === 5 || day.day() === 6;

          return (
            <Box
              key={index}
              onClick={() => onDayClick?.(day, rowId)}
              sx={({ spacing, palette: { background, getContrastText } }) => ({
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                padding: spacing(2),
                border: "1px solid rgba(224, 224, 224)",
                backgroundColor: background.paper,
                color: getContrastText(background.paper),
                ...(isWeekendDay && {
                  backgroundColor: colors.yellow[200],
                  color: getContrastText(colors.yellow[200]),
                }),
                ...(isToday && {
                  backgroundColor: colors.red[200],
                  color: getContrastText(colors.red[200]),
                }),
              })}
            >
              {onRenderDay?.(day, rowId)}
            </Box>
          );
        })}
      </>
    );
  }
);

CalendarRow.displayName = "CalendarRow";
