import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Chip, IconButton, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { FC, memo, PropsWithChildren, useCallback } from "react";
import { translations } from "../../translations";
import { RoomNumbersSubscription } from "../../__generated__/types";
import { useSelectedPropertyContext } from "../SelectedPropertyProvider";
import { useCalendarContext } from "./CalendarContext";
import { CalendarRow } from "./CalendarRow";

export const CalendarBase: FC<
  PropsWithChildren<{ room: RoomNumbersSubscription["room"] }>
> = memo(({ room, children, onRenderDay, onDayClick }) => {
  const { date, calendarRange, onPrevClick, onNextClick, onMonthSelect } =
    useCalendarContext();
  const { selected_locale } = useSelectedPropertyContext();

  const onRenderHeaderDay = useCallback(
    (day) => (
      <Box>
        <div>{day.format("dd")}</div>
        <div>{day.format("D")}</div>
      </Box>
    ),
    [date]
  );

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
      })}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(1),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        })}
      >
        <IconButton>
          <ChevronLeft fontSize="large" onClick={onPrevClick} />
        </IconButton>
        <Box>
          <TextField
            type="month"
            value={date.format("YYYY-MM")}
            defaultValue={date.format("YYYY-MM")}
            onChange={(e) => {
              onMonthSelect(dayjs(e.target.value));
            }}
          />
        </Box>
        <IconButton>
          <ChevronRight fontSize="large" onClick={onNextClick} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "grid",
          overflow: "auto",
          position: "relative",
          gridTemplateColumns: `repeat(${calendarRange.length + 1}, 1fr)`,
        }}
      >
        <CalendarRow
          label={translations[selected_locale].number_label}
          onRenderDay={onRenderHeaderDay}
        />
        {room.map(({ id, room_number, room_type }) => (
          <CalendarRow
            onDayClick={onDayClick}
            onRenderDay={onRenderDay}
            rowId={id}
            key={id}
            label={
              <Box sx={{ textAlign: "center", display: "column" }}>
                <Box>{room_number}</Box>
                <Chip label={room_type.room_type} size="small" />
              </Box>
            }
          />
        ))}
        {children}
        {room.length === 0 && (
          <Box
            sx={{
              p: 1,
              gridArea: `2/1/span 1/span ${calendarRange.length + 1}`,
            }}
          >
            <Typography>
              {translations[selected_locale].no_added_rooms}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
});

CalendarBase.displayName = "CalendarBase";
