import dayjs from "dayjs";
import {
  FC,
  PropsWithChildren,
  memo,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";

const range = (s, e) =>
  Array.from({ length: e.diff(s, "day") + 1 }, (_, i) => s.add(i, "day"));

const CalendarContext = createContext(undefined);

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error(
      "useCalendarContext must be used within a CalendarProvider"
    );
  }
  return context;
};

export const CalendarProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [date, setDate] = useState(dayjs());
  const value = useMemo(() => {
    const start = date.startOf("month");
    const end = date.endOf("month");
    const calendarRange = range(start, end);
    return {
      date,
      calendarRange,
      start,
      end,
      onPrevClick: () => setDate(start.subtract(1, "month")),
      onNextClick: () => setDate(end.add(1, "month")),
      onMonthSelect: (date) => setDate(date),
    };
  }, [date]);
  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
});

CalendarProvider.displayName = "CalendarProvider";
