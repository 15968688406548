import { Box, Container, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useCurrencyProvider } from "../components/CurrencyPovider";
import { CalendarBase } from "../components/schedule/CalendarBase";
import { CalendarProvider } from "../components/schedule/CalendarContext";
import { useSelectedPropertyContext } from "../components/SelectedPropertyProvider";
import {
  useCreatePriceMutation,
  useDeletePriceMutation,
  useRoomNumbersPricesSubscription,
  useUpdatePriceMutation,
} from "../__generated__/types";

const PricesPage = () => {
  const { selected, currency_code } = useSelectedPropertyContext();
  const { data } = useRoomNumbersPricesSubscription({
    variables: { property_id: selected },
  });
  const [selectedDates, setSelectedDates] = useState([]);

  const [updatePrice] = useUpdatePriceMutation();
  const [createPrice] = useCreatePriceMutation();
  const [deletePrice] = useDeletePriceMutation();
  const { currency } = useCurrencyProvider();
  return (
    <Container
      sx={{ mt: 2, display: "flex", flexDirection: "column" }}
      maxWidth={false}
    >
      <CalendarProvider>
        {data && (
          <CalendarBase
            room={data.room}
            onDayClick={(day, rowId) =>
              setSelectedDates((p) => [...p, { day, rowId }])
            }
            onRenderDay={(day, rowId) => {
              const { room_prices } = data?.room.find(
                (room) => room.id === rowId
              );
              const dayPrice = room_prices.find(({ date }) =>
                dayjs(date).isSame(day)
              );
              const isOpen = selectedDates.some(
                (sday) => sday.day.isSame(day) && sday.rowId === rowId
              );
              return (
                <Box>
                  {isOpen ? (
                    <TextField
                      type="number"
                      size="small"
                      sx={{ width: 80 }}
                      defaultValue={dayPrice?.amount}
                      onBlur={(e) => {
                        if (dayPrice) {
                          if (Number(e.target.value) !== 0) {
                            updatePrice({
                              variables: {
                                id: dayPrice.id,
                                amount: Number(e.target.value),
                              },
                            });
                          } else {
                            deletePrice({ variables: { id: dayPrice.id } });
                          }
                        } else {
                          e.target.value &&
                            createPrice({
                              variables: {
                                room_id: rowId,
                                amount: Number(e.target.value),
                                date: day.format("YYYY-MM-DD"),
                                currency_code: currency_code,
                              },
                            });
                        }
                        setSelectedDates((prev) =>
                          prev.filter(
                            (i) => !i.day.isSame(day) || i.rowId !== rowId
                          )
                        );
                      }}
                    />
                  ) : (
                    <Typography variant="caption">
                      {dayPrice &&
                        currency(dayPrice.amount, dayPrice.currency_code)}
                    </Typography>
                  )}
                </Box>
              );
            }}
          ></CalendarBase>
        )}
      </CalendarProvider>
    </Container>
  );
};

export default PricesPage;
